import { createAction, createActionGroup, props } from '@ngrx/store';
import { TeamMember } from 'src/core/interfaces/team/team.interface';
import {
  UserGoalsStatusMetric,
  UserLastMeetingMetric,
} from 'src/core/interfaces/user/metrics.interface';
import {
  NGRXManagerDashboardSource,
  NGRXManagerDashboardType,
} from './manager-dashboard-action.types';

export const ManagerDashboardActions = createActionGroup({
  source: NGRXManagerDashboardSource.ManagerDashboardSource,

  events: {
    [NGRXManagerDashboardType.loadGoalsStatusPerUserFromDB]: props<{
      aggregation: UserGoalsStatusMetric[];
    }>(),

    [NGRXManagerDashboardType.loadLastMeetingPerUserFromDB]: props<{
      aggregation: UserLastMeetingMetric[];
      teammates: TeamMember[];
    }>(),
  },
});

export const initManagerDashboardsStore = createAction(
  NGRXManagerDashboardType.initManagerDashboardStore,
);

export const removeAllManagerDashboards = createAction(
  NGRXManagerDashboardType.removeAll,
);
