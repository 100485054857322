export enum NGRXManagerDashboardType {
  initManagerDashboardStore = 'Init Manager Dashboard Store',

  loadDashboardFromDB = 'Load Dashboard From DB',
  loadGoalsStatusPerUserFromDB = 'Load Goals Status Per User From DB',
  loadLastMeetingPerUserFromDB = 'Load Last Meeting Per User From DB',

  removeAll = 'Remove All',
}

export enum NGRXManagerDashboardSource {
  ManagerDashboardSource = 'Manager Dashboard Source',
}
