export enum Metrics {
  NRGoalsPerTeam = 'goalsPerTeam',
  NRFeedbacksPerTeam = 'feedbacksPerTeam',
  GoalsStatusPerUser = 'goalsStatusPerUser',
  LastMeetingPerUser = 'lastMeetingPerUser',
}

export const MetricLabel = {
  [Metrics.NRGoalsPerTeam]: '#goals/Team',
  [Metrics.NRFeedbacksPerTeam]: '#reviews/Team',
  [Metrics.GoalsStatusPerUser]: 'Goals status/Teammate',
  [Metrics.LastMeetingPerUser]: 'Last 1 to 1',
};
